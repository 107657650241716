import * as React from "react";

import { Buttons } from "./Buttons";

export default function GlobalAntOverride(): JSX.Element {
  return (
    <>
      <Buttons />
    </>
  );
}
